import type { Payment } from '../types'
import { isFinal, statusCodes } from './statusCodes'
import { get } from 'svelte/store'
import { payment as paymentStore, error } from '$lib/stores'

/**
 * Handle payment expiration timeout.
 *
 * If the payment is in a state that can expire and no error
 * has occurred, set an error after 10 minutes (if the
 * conditions for expiration are still met).
 */
const handleExpirationTimeout = (payment: Payment) => {
    if (
        [
            statusCodes.NEW,
            statusCodes.EXTERNAL_SESSION_CREATED,
            statusCodes.OPERATOR_RESOLVED,
            statusCodes.MSISDN_RESOLVED,
        ].includes(payment.status) &&
        !get(error)
    ) {
        const createdAt = Date.parse(payment.createdAt)
        const expiration = createdAt + 600 * 1000 // Dealing with milliseconds here
        const expiresIn = expiration - Date.now()

        setTimeout(() => {
            if (isFinal(get(paymentStore).status) || get(error)) {
                return
            }

            error.set({ code: 'PAYMENT_EXPIRED' })
        }, expiresIn)
    }
}

export default handleExpirationTimeout
