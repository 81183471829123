import { get, writable } from 'svelte/store'
import SiruAxios from '../SiruAxios'
import { payment, wallet } from './index'
import type { Payment, WalletPaymentAuthentications } from '$lib/types'
import { isFinal } from '../utilities/statusCodes'
import { isEqual } from 'lodash-es'
import { browser } from '$app/environment'
import { jwtDecode } from 'jwt-decode'

export const LOCALSTORAGE_WALLET_PAYMENT_AUTHENTICATIONS = 'wallet_payment_authentications'

export const createWalletPaymentAuthentications = () => {
    const initialState: WalletPaymentAuthentications = {}

    if (browser && localStorage.getItem(LOCALSTORAGE_WALLET_PAYMENT_AUTHENTICATIONS)) {
        // @ts-ignore
        const authentications = JSON.parse(localStorage.getItem(LOCALSTORAGE_WALLET_PAYMENT_AUTHENTICATIONS))
        // Loop over authentications in localstorage. If token is not expired, add it to our initialState
        Object.keys(authentications).forEach(key => {
            if (!isTokenExpired(authentications[key])) {
                initialState[key] = authentications[key]
            }
        })
    }

    const { subscribe, set, update } = writable(initialState)

    return {
        submitMsisdn: async (msisdn: string, webOTPHost: string) => {
            const { data } = await SiruAxios.post<{ purchase: Payment; new_user: boolean; pk: boolean }>(
                `pay/rest/${get(payment).uuid}/verifynumber`,
                { username: msisdn },
                {
                    useGlobalErrorHandler: false,
                    // Add the hostname to the headers so the backend knows how to fill the webotp data to sms
                    headers: { 'x-webotp-host': webOTPHost },
                },
            )

            if (!isEqual(data.purchase, get(payment)) && !isFinal(get(payment).status)) {
                payment.set(data.purchase)
            }

            if (data.new_user) {
                wallet.setIsNewWallet(true)
            }

            return data
        },
        submitPin: async (username: string, pin: string, uuid: string) => {
            const { data } = await SiruAxios.post<{ success: boolean; token: string }>(
                `pay/wallet/login`,
                { username, password: pin, purchase_uuid: uuid },
                { useGlobalErrorHandler: false },
            )

            SiruAxios.defaults.headers.common['Authorization'] = `Bearer ${data.token}`
            update(state => ({ ...state, [get(payment).uuid]: data.token }))

            return data
        },
        add: (uuid: string, token: string) => update(state => ({ ...state, [uuid]: token })),
        subscribe,
        set,
    }
}

const isTokenExpired = (token: string) => {
    const { exp } = jwtDecode(token)

    if (!exp) {
        return true
    }

    return exp < Date.now() / 1000
}
