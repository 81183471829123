import { writable } from 'svelte/store'
import type { Variant4Info } from '../types'

export const TYPE_GATEWAY = 'gateway'
export const TYPE_HOSTED = 'hosted'

export const FONIXMOBILE_API = 'fonixmobile'
export const IVY_API = 'ivy'

export const createVariant4Info = () => {
    const initialState: Variant4Info = {
        type: null,
        initiate_payment_url: null,
        api: null,
    }

    const { subscribe, set } = writable(initialState)

    return {
        subscribe,
        set,
    }
}
