import { get, writable } from 'svelte/store'
import SiruAxios from '../SiruAxios'
import type { Payment, PaymentResponse } from '../types'
import {
    alerts,
    payment as paymentStore,
    error,
    fetching,
    wallet,
    kyc,
    numberConfirmed,
    branding,
    publicNumber,
    productInfo,
    hasPasskey,
    unconfirmedPhoneNumber,
    variants,
    variant4Info,
    subscription,
    exchangeRate,
} from './index'
import { AxiosError } from 'axios'
import { isFinal, statusCodes } from '../utilities/statusCodes'
import { isEqual } from 'lodash-es'
import { locale } from 'svelte-i18n'
import { getLanguageFromPurchase, supportedLocales } from '$lib/i18n'
import handleExpirationTimeout from '../utilities/handleExpirationTimeout'

export const createPayment = () => {
    const initialState: Payment | {} = {}

    const { subscribe, set, update } = writable(initialState)

    return {
        fetch: async (uuid: string) => {
            const { data } = await SiruAxios.get<PaymentResponse>(`pay/rest/${uuid}`, {
                headers: {
                    'Original-Referrer': document.referrer,
                },
            })

            set(data.purchase)

            if (supportedLocales.includes(getLanguageFromPurchase(data.purchase.customerLocale))) {
                locale.set(getLanguageFromPurchase(data.purchase.customerLocale))
            }

            if (data.purchase.status === statusCodes.CANCELED) {
                error.set({ code: data.errorCode || 'PAYMENT_CANCELED' })
            }
            if (data.purchase.status === statusCodes.FAILED) {
                error.set({ code: data.errorCode || 'failed' })
            }

            if (data.kycRequired) {
                kyc.setKycRequired()
            }

            // @ts-ignore
            numberConfirmed.set(data.number_confirmed)

            if (data.publicNumber) {
                publicNumber.set(data.publicNumber)
            }

            if (data.unconfirmedNumber) {
                unconfirmedPhoneNumber.set(data.unconfirmedNumber)
            }

            if (data.exchangeRate) {
                exchangeRate.set(data.exchangeRate)
            }

            variants.set(data.variants)

            if (data.variant4info) {
                variant4Info.set(data.variant4info)
            }

            if (data.productInfo) {
                productInfo.set(data.productInfo)
            }

            if (data.subscription) {
                subscription.setSubscription(data.subscription)
            }

            if (data.branding) {
                branding.set(data.branding)
            }

            if (data.pk === true) {
                hasPasskey.set(true)
            }

            handleExpirationTimeout(data.purchase)

            return data
        },
        doPoll: async () => {
            try {
                const { data } = await SiruAxios.get<PaymentResponse>(`pay/rest/${get(paymentStore).uuid}/poll`, {
                    headers: {
                        'Original-Referrer': document.referrer,
                    },
                    useGlobalErrorHandler: false,
                })
                // If the payment has changed and is not in a final state, update the store
                if (!isEqual(data.purchase, get(paymentStore)) && !isFinal(get(paymentStore).status)) {
                    update(() => data.purchase)
                }

                if (data.purchase.status === statusCodes.CANCELED) {
                    error.set({ code: data.errorCode || 'PAYMENT_CANCELED' })
                }
                if (data.purchase.status === statusCodes.FAILED) {
                    error.set({ code: data.errorCode || 'failed' })
                }

                if (data.wallet?.balance) {
                    wallet.setBalance(data.wallet.balance)
                }
            } catch (e) {
                if ((e as AxiosError).response?.status === 500) {
                    alerts.add('danger', 'payment.message.poll_500')
                } else if ((e as AxiosError).response?.status === 503) {
                    error.set({ status: 503 })
                }
            }
        },
        checkForRefusals: async () => {
            const { data } = await SiruAxios.get<{ purchase: Payment; errorCode: string }>(
                `pay/rest/${get(paymentStore).uuid}/check`,
            )

            // If the payment has changed and is not in a final state, update the store
            if (!isEqual(data.purchase, get(paymentStore)) && !isFinal(get(paymentStore).status)) {
                update(() => data.purchase)
            }

            if (data.errorCode) {
                error.set({ code: data.errorCode })
                return
            }

            if (data.purchase.status === statusCodes.FAILED) {
                error.set({ code: 'failed' })
            }

            if (data.purchase.status === statusCodes.CANCELED) {
                error.set({ code: 'PAYMENT_CANCELED' })
            }
        },
        confirmIpPayment: async () => {
            fetching.startFetching('confirm')

            const { data } = await SiruAxios.post<{ purchase: Payment; errorCode: string }>(
                `pay/rest/${get(paymentStore).uuid}/pay/ip`,
            )

            if (!isEqual(data.purchase, get(paymentStore)) && !isFinal(get(paymentStore).status)) {
                update(() => data.purchase)
            }

            if (data.errorCode) {
                error.set({ code: data.errorCode })
                return
            }

            if (data.purchase.status === statusCodes.FAILED) {
                error.set({ code: 'failed' })
            }

            fetching.cancelFetching('confirm')
        },
        confirmWalletPayment: async () => {
            fetching.startFetching('confirm')

            const { data } = await SiruAxios.post<{ purchase: Payment; errorCode: string }>(
                `pay/rest/${get(paymentStore).uuid}/pay`,
            )

            if (!isEqual(data.purchase, get(paymentStore)) && !isFinal(get(paymentStore).status)) {
                update(() => data.purchase)
            }

            if (data.errorCode) {
                error.set({ code: data.errorCode })
                return
            }

            if (data.purchase.status === statusCodes.FAILED) {
                error.set({ code: 'failed' })
            }

            fetching.cancelFetching('confirm')
        },
        initiateV4payment: async (InitiatePaymentUrl: string) => {
            fetching.startFetching('confirm')

            const { data } = await SiruAxios.post<{ purchase: Payment; errorCode: string }>(InitiatePaymentUrl)

            if (!isEqual(data.purchase, get(paymentStore)) && !isFinal(get(paymentStore).status)) {
                update(() => data.purchase)
            }

            if (data.errorCode) {
                error.set({ code: data.errorCode })
                return
            }

            if (data.purchase.status === statusCodes.FAILED) {
                error.set({ code: 'failed' })
            }

            fetching.cancelFetching('confirm')

            return data
        },
        finalize: async () => {
            if (get(fetching).finalize?.fetching) return

            fetching.startFetching('finalize')
            const { data } = await SiruAxios.get<{ purchase: Payment; errorCode: string }>(
                `pay/rest/${get(paymentStore).uuid}/finalize`,
            )
            fetching.cancelFetching('finalize')
            return data
        },
        subscribe,
        set,
    }
}
