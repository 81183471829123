import { get, writable } from 'svelte/store'
import type { KYC } from '$lib/types'
import SiruAxios from '../SiruAxios'
import { fetching } from '$lib/stores'
import { captureException } from '$lib/utilities/errorTrackingTools.js'

// Different kyc types
export const TYPE_IFRAME = 'iframe'
export const TYPE_REDIRECT = 'redirect'

export const createKYC = () => {
    const initialState: KYC = { required: false, completed: false, failed: false, type: null, url: null }

    const { subscribe, set, update } = writable(initialState)

    return {
        fetchKycProcess: async (uuid: string) => {
            const { data } = await SiruAxios.get(`pay/rest/authentication/${uuid}`)

            return update(state => ({
                ...state,
                type: data.type,
                url: data.url,
            }))
        },
        checkTeliaKyc: async (uuid: string, code: string) => {
            if (get(fetching).check_telia_kyc?.fetching) return

            fetching.startFetching('check_telia_kyc')

            try {
                await SiruAxios.get(`pay/rest/authentication/${uuid}/userinfo/telia/${code}`, {
                    useGlobalErrorHandler: false,
                })
                update(state => ({ ...state, completed: true }))
            } catch (e) {
                captureException(e)
                update(state => ({ ...state, failed: true }))
            }

            fetching.cancelFetching('check_telia_kyc')
        },
        setKycRequired: () => update(state => ({ ...state, required: true })),
        setKycCompleted: () => update(state => ({ ...state, completed: true })),
        setKycFailed: () => update(state => ({ ...state, failed: true })),
        retryKyc: () => update(state => ({ ...state, failed: false })),
        subscribe,
        set,
        reset: () => set({ ...initialState }),
    }
}
