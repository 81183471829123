import { init, register } from 'svelte-i18n'
import { browser } from '$app/environment'
import { captureMessage } from '$lib/utilities/errorTrackingTools.js'

export const defaultLocale = 'en'

export const supportedLocales = ['en', 'fi', 'sv']

export const getLanguageFromPurchase = code => {
    const splitted = code.split('_')
    return splitted[0]
}

export const getLanguageFromBCP47 = code => {
    const splitted = code.split('-')
    return splitted[0]
}

export const getCountryCodeFromLanguage = (lang = null) => {
    const splitted = lang.split('_')
    if (splitted.length > 1) {
        return splitted[1]
    }
    // Hacked some country codes for short language syntax
    switch (splitted[0]) {
        case 'sv':
            return 'SE'
        case 'en':
            return 'GB'
        default:
            return splitted[0]
    }
}

register('en', () => import('./locales/en.json'))
register('fi', () => import('./locales/fi.json'))
register('sv', () => import('./locales/sv.json'))

/**
 * If we are in browser, get the language from the url
 *
 * This is a small hack for the client side. The lang is determined in the url, and it works
 * ok server side, but on client side, the page has a quick flash of the fallbacklocale.
 * So, let us just get the fallbacklocale from the url on the client load.
 */
let urlLanguageCode
if (browser) {
    ;[, urlLanguageCode] = window.location.pathname.match(/\/([a-zA-Z]{2})($|\/)/) || []
}

init({
    ignoreTag: false,
    fallbackLocale: supportedLocales.includes(urlLanguageCode) ? urlLanguageCode : defaultLocale,
    handleMissingMessage: ({ locale, id }) => {
        const message = `Missing message for ${locale} translation: ${id}`
        captureMessage(message)
        console.error(message)
    },
})
