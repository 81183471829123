/**
 * Purchase statusCodes-object
 */
export const statusCodes = {
    NEW: 1,
    UNCONFIRMED: 2,
    CONFIRMED: 3,
    CANCELED: 4,
    FAILED: 5,
    CALL_STARTED: 6,
    OPERATOR_RESOLVED: 7,
    MSISDN_RESOLVED: 8,
    FUNDS_RESERVED: 9,
    EXTERNAL_SESSION_CREATED: 10,
}

/**
 * Check if purchase status code is final.
 */
export const isFinal = (code: number): boolean =>
    [statusCodes.CONFIRMED, statusCodes.CANCELED, statusCodes.FAILED].includes(code)
