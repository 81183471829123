import { writable } from 'svelte/store'
import type { DepositMethod, Wallet } from '$lib/types'
import { wallet } from './index'
import SiruAxios from '../SiruAxios'

export const createDepositables = () => {
    const initialState: DepositMethod[] = []

    const { subscribe, set } = writable([...initialState])

    return {
        fetch: async (uuid: string) => {
            const { data } = await SiruAxios.get<{ wallet: Wallet; depositMethods: DepositMethod[] }>(
                `pay/rest/${uuid}/depositables`,
            )

            // This api also returns the wallet; update balance
            wallet.setBalance(data.wallet.balance)

            return set(data.depositMethods)
        },
        subscribe,
        set,
        reset: () => set([...initialState]),
    }
}
