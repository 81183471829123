import { writable } from 'svelte/store'
import type { Subscription, SubscriptionInterval } from '$lib/types'

export const createSubscription = () => {
    const initialState: Subscription | false = false

    const { subscribe, set } = writable(initialState)

    return {
        setSubscription: (subscription: Subscription) => {
            const newState = {
                ...decodeInterval(subscription.interval),
                trialPeriod: false,
                actualPriceAfterTrialPeriod: subscription.actualPriceAfterTrialPeriod,
            }

            if (subscription.trialPeriod) {
                // @ts-ignore
                newState.trialPeriod = decodeInterval(subscription.trialPeriod)
            }

            // @ts-ignore
            set(newState)
        },
        subscribe,
        set,
    }
}

/**
 * Helper to decode subscription intervals
 */
const decodeInterval = (interval: string): SubscriptionInterval => {
    // Interval is in format "1 months" or similar
    let [intervalLength, intervalUnit] = interval.split(' ')
    // Always remove possible trailing "s" from interval unit
    intervalUnit.replace('s', '')
    return { intervalLength, intervalUnit }
}
