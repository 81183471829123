import { writable } from 'svelte/store'
import SiruAxios from '../SiruAxios'
import { alerts } from './index'

export const createNumberConfirmed = () => {
    const initialState: boolean | null = null

    const { subscribe, set } = writable(initialState)

    return {
        confirmNumber: async (uuid: string, pin: string) => {
            const { data } = await SiruAxios.post(
                `pay/rest/${uuid}/confirmation-confirm`,
                {
                    pin: pin,
                },
                {
                    useGlobalErrorHandler: false,
                },
            )

            if (data.success) {
                set(true)
            } else {
                alerts.add('danger', 'payment.sms_verification.message.invalid_pin_code')
            }
        },
        subscribe,
        set,
        reset: () => set(null),
    }
}
