import { writable } from 'svelte/store'
import type { PaymentError } from '../types'

export const createError = () => {
    const initialState: PaymentError | null = null

    const { subscribe, set } = writable(initialState)

    return {
        subscribe,
        set: (error: PaymentError) => set(error),
        reset: () => set(null),
    }
}
