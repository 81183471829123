import { createFetching } from './fetching'
import { createAlerts } from './alerts.js'
import { createWallet } from './wallet'
import { createDepositables } from './depositables'
import { createFingerprint } from './fingerprint'
import { createKYC } from './kyc'
import { writable } from 'svelte/store'
import { createNumberConfirmed } from './numberConfirmed'
import { createPayment } from './payment'
import { createSubscription } from './subscription'
import { createWalletPaymentAuthentications } from './walletPaymentAuthentications'
import { createError } from './error'
import { createVariant4Info } from './variant4info'

export const alerts = createAlerts()

export const branding = writable()

export const depositables = createDepositables()

export const error = createError()

export const exchangeRate = writable()

export const fetching = createFetching()

export const fingerprint = createFingerprint()

export const hasPasskey = writable(false)

export const numberConfirmed = createNumberConfirmed()

export const payment = createPayment()

export const productInfo = writable({})

export const publicNumber = writable(null)

export const subscription = createSubscription()

export const unconfirmedPhoneNumber = writable(null)

export const variant4Info = createVariant4Info()

export const variants = writable([])

export const kyc = createKYC()

export const wallet = createWallet()

export const walletPaymentAuthentications = createWalletPaymentAuthentications()
