import { writable } from 'svelte/store'
import type { Wallet, Money } from '$lib/types'

/**
 * Custom wallet store creator
 */
export const createWallet = () => {
    const initialState: Wallet = {
        balance: { amount: 0, currency: null },
        country: null,
        newWallet: false,
    }

    const { subscribe, set, update } = writable({ ...initialState })

    return {
        setBalance: (newBalance: Money) =>
            update(state => ({
                ...state,
                balance: newBalance,
            })),
        setIsNewWallet: (isNewWallet: boolean) => update(state => ({ ...state, newWallet: isNewWallet })),
        subscribe,
        set,
        reset: () => set({ ...initialState }),
    }
}
