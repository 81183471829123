import { writable } from 'svelte/store'
import type { FetchingState } from '../types'

/**
 * Custom fetching store creator
 */
export const createFetching = () => {
    const initialState: FetchingState = {}
    const { subscribe, update, set } = writable({ ...initialState })

    return {
        subscribe,
        reset: () => set({ ...initialState }),
        startFetching: (key: string) =>
            update(state => {
                state[key] = {
                    ...state[key],
                    fetching: true,
                }
                return state
            }),
        cancelFetching: (key: string) =>
            update(state => {
                state[key] = {
                    ...state[key],
                    fetching: false,
                }
                return state
            }),
        setFetched: (key: string) =>
            update(state => {
                state[key] = {
                    ...state[key],
                    fetched: true,
                }
                return state
            }),
    }
}
